let counter = 0;
$(function(){
  counter = 20;
  $('#load-routes').on('click', function(e){
    const route_type = $('#route_type').val();
    loadRoutes(20, route_type);
    $('#load-routes').addClass('none');
    e.stopPropagation();
  })
});

const loadRoutes = (offset, route_type) => {
  $.ajax({
    method: 'get',
    url: '/api/v1/routes',
    data: { offset: offset, route_type: route_type }
  }).done(function(data) {
    if (data.length > 0) {
      data.forEach(route => {
        const top_route_column = route_type == 'top_routes' ? '' : `<td>${route.frequency == 'top_route' ? 'Top Route' : ''}</td>`;
        const scrapers = route.scrapers.map(scraper => `<a href='/scrapers/${scraper.id}'>${scraper.name}</a>`);
        let route_switch = '';
        if (route.frequency == 'top_route') {
          route_switch = `<a href='/routes/${route.id}/normalize'>Remove from top route</a>`;
        }
        else if (route.toproutes_banned == false){
          route_switch = `<a href='/routes/${route.id}/top'>Make it top route</a>`;
        }
        let winter_route_switch = '';
        if (route.winter_route == true) {
          winter_route_switch = `<a href='/routes/${route.id}/unwinterize'>Remove from winter route</a>`;
        }
        else if (route.winter_route == false){
          winter_route_switch = `<a href='/routes/${route.id}/winterize'>Make it winter route</a>`;
        }
        let topban_switch = '';
        if (route.frequency == 'top_route') {
          topban_switch = `<a href='/routes/${route.id}/ban_toproute'>Ban from top route</a>`;
        }
        else if (route.toproutes_banned){
          topban_switch = `<a href='/routes/${route.id}/unban_toproute'>Unban top route</a>`;
        }
        let ban_switch = '';
        if (route.banned) {
          ban_switch = `<a href='/routes/${route.id}/unban_route'>Unban route</a>`;
        }
        else {
          ban_switch = `<a href='/routes/${route.id}/ban_route'>Ban route</a>`;
        }
        let unblockers = '';
        if (route.banned) {
          unblockers = route.scrapers.map(scraper => `<a href="/routes/${route.id}/unban_route/${scraper.id}">Unblock ${scraper.name}</a>&nbsp`);
        }
        else {
          unblockers = route.ban_route_scrapers.map(ban_route_scraper => (
            `<a data-confirm="Are you sure?" rel="nofollow" data-method="delete" href="/ban_route_scrapers/${ban_route_scraper.id}">
              Unblock ${(ban_route_scraper.scraper ? ban_route_scraper.scraper.site_name : ban_route_scraper.id)}
            </a>&nbsp`
          ));
        }
        $('#routes').append(`
          <tr>
            <td>${counter+=1}</td>
            <td><a href='/stations/${route.departure_station.id}'>${route.departure_station.name}</a></td>
            <td><a href='/stations/${route.arrival_station.id}'>${route.arrival_station.name}</a></td>
            <td>${route.requested_times}</td>
            ${top_route_column}
            <td>${scrapers}</td>
            <td>${route.departure_station.name}+${route.arrival_station.name}</td>
            <td>${route.price_offset}</td>
            <td>${route.last_scanned_at}</td>
            <td><a href='/routes/${route.id}'>Show</a></td>
            <td>${route_switch}</td>
            <td>${winter_route_switch}</td>
            <td>${topban_switch}</td>
            <td>${ban_switch}</td>
            <td>${unblockers}</td>
            <td><a data-confirm="Are you sure?" rel="nofollow" data-method="delete" href="/routes/${route.id}">Destroy</a></td>
          </tr>
        `);
      });
      setTimeout(() => loadRoutes(offset + 20, route_type), 100);
    }
  });
}
