$(function(){
  $('#load-reports').on('click', function(e){
    var year = $('#year').val();
    loadCronReports(year, 20);
    $('#load-reports').addClass('none');
    e.stopPropagation();
  })
});

const loadCronReports = (year, offset) => {
  $.ajax({
    method: 'get',
    url: '/api/v1/cron_reports',
    data: { year: year, offset: offset }
  }).done(function(data) {
    if (data.length > 0) {
      data.forEach(report => {
        $('#cron-reports-body').append(`
          <tr>
            <td>${report.title}</td>
            <td>${report.total_requests}</td>
            <td>${report.total_time}</td>
            <td>${report.success_requests}</td>
            <td>${report.success_average_time}</td>
            <td>${report.success_rate}%</td>
            <td>${report.processed_rate}%</td>
            <td>${report.efficiency_rate}</td>
            <td>${report.created_at}</td>
            <td>${report.updated_at}</td>
            <td>${report.estimated_left_time}</td>
            <td>${report.estimated_finish_time}</td>
          </tr>
        `);
      });
      setTimeout(() => loadCronReports(year, offset + 20), 100);
    }
  });
}
