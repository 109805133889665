$(function(){
  $(document).on("click", "#add_new_station_name", () => {
    $("#station-names-container").append($('.station-name-temp').clone().removeClass('d-none station-name-temp').addClass('station-name'))
  });

  $(document).on("click", "#select_all", () => {
    if($("#select_all").is(":checked")) {
      $(".log").prop("checked", true);
    }
    else {
      $(".log").prop("checked", false);
    }
  });

  $(".log").click(() => {
    if($(this).is(":checked") == false) {
      $("#select_all").prop("checked", false);
    }

    if($(".log:not(:checked)").length == 0)
      $("#select_all").prop("checked", true);
  });

  $(document).on('click', '#station-names-container .station-name-cancel', function(){
    $(this).closest('.station-name').append("<input type='hidden' name='station[station_names_attributes][][_destroy]' value='1'>")
    $(this).closest('.station-name').addClass('d-none')
  })
})
