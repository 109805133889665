$(function(){
  $('#load-schedules').on('click', function(e){
    var year = $('#year').val();
    loadSchedules(year, 20);
    $('#load-schedules').addClass('none');
    e.stopPropagation();
  })
});

const loadSchedules = (year, offset) => {
  $.ajax({
    method: 'get',
    url: '/api/v1/schedules',
    data: { year: year, offset: offset }
  }).done(function(data) {
    if (data.length > 0) {
      data.forEach(schedule => {
        $('#schedules-body').append(`
          <tr>
            <td>${schedule.scrapers_list}</td>
            <td>${schedule.server_pool}</td>
            <td>${schedule.start_date}</td>
            <td>${schedule.end_date}</td>
            <td>${schedule.status}</td>
            <td>${schedule.step}</td>
            <td>${schedule.finished_workers}</td>
            <td>${schedule.generation_status}</td>
            <td>${schedule.distribution_status}</td>
            <td>${schedule.collection_status}</td>
            <td>${schedule.scheduled_by}</td>
            <td>${schedule.stop_running ? `<a href='${schedule.stop_running}'>Stop Running</a>` : ''}</td>
            <td><a href="/schedules/${schedule.id}">Show</a></td>
            <td><a href="/schedules/${schedule.id}/edit">Edit</a></td>
            <td><a data-confirm="Are you sure?" rel="nofollow" data-method="delete" href="/schedules/${schedule.id}">Destroy</a></td>
          </tr>
        `);
      });
      setTimeout(() => loadSchedules(year, offset + 20), 100);
    }
  });
}
